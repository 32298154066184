/******************************************************************************
 * SuperTable.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import "../css/SuperTable.css";

function SuperTable(props) {
    const columns = useMemo(() => props.columns, [ props.columns ]);
    const data = useMemo(() => props.data, [ props.data ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // footerGroups,
        // rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
    }, useSortBy, usePagination);

    return (
        <div>
            <table className="supertable" {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr className="supertr" {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th className="superth" {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                <span>
                            {/*{column.isSorted ? (column.isSortedDesc ? ' <img draggable="false" role="img" class="emoji" alt="🔽" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53d.svg">' : ' <img draggable="false" role="img" class="emoji" alt="🔼" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53c.svg">') : ''}*/}
                                    {/*        {column.isSorted ? (column.isSortedDesc ? ' ^' : ' v') : ''}*/}
                                    {column.isSorted ? (column.isSortedDesc ? <CaretUpFill/> : <CaretDownFill/>) : ""}
                        </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    page.map(row => {
                        prepareRow(row);
                        return (
                            <tr className="supertr" {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td className="supertd" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
            <br/>
            <div className=".superpagination text-center">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                </button>
                {" "}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                </button>
                {" "}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                </button>
                {" "}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                </button>
                &nbsp;&nbsp;
                <span>
          Page{" "}
                    <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>&nbsp;&nbsp;
        </span>
                <span>
          | Go to page:&nbsp;&nbsp;
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "70px" }}
                    />
        </span>{" "}
                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[ 10, 20, 30, 40, 50 ].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default SuperTable;