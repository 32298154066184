/******************************************************************************
 * http.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

const HTTP =
    {
        OK_200            : 200,    // OK
        CREATED_201       : 201,    // Created
        NO_CONTENT_204    : 204,    // No Content
        BAD_REQUEST_400   : 400,    // Bad Request (incorrect syntax)
        UNAUTHORIZED_401  : 401,    // Unauthorized (unauthenticated)
        FORBIDDEN_403     : 403,    // Forbidden (authenticated, but not authorized)
        NOT_FOUND_404     : 404,    // Not Found
        INTERNAL_ERROR_500: 500,    // Internal Error
        NOT_AVAILABLE_503 : 503,    // Service Not Available (overloaded?)
        TIMEOUT_504       : 504     // Gateway Timeout
    };

const isHttpOk = (code) => {
    return code < 300;
};

const getDefaultMessage = (code) => {
    switch (code) {
        case HTTP.OK_200:
            return "OK";
        case HTTP.CREATED_201        :
            return "Successfully created";
        case HTTP.NO_CONTENT_204        :
            return "Successfully deleted";
        case HTTP.BAD_REQUEST_400        :
            return "Bad Request";
        case HTTP.UNAUTHORIZED_401        :
            return "Unauthorized";
        case HTTP.FORBIDDEN_403        :
            return "Forbidden";
        case HTTP.NOT_FOUND_404        :
            return "Not Found";
        case HTTP.INTERNAL_ERROR_500        :
            return "Internal Error";
        case HTTP.NOT_AVAILABLE_503        :
            return "Service Not Available";
        case HTTP.TIMEOUT_504        :
            return "Gateway Timeout";
        default:
            return `Unknown code: ${code}`
    }
};

export {
    HTTP,
    isHttpOk,
    getDefaultMessage
};
