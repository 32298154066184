import { useEffect } from "react";

import * as UserErrors from "../api/userErrors.api.js";

import { HTTP } from "../constants/http.js";
import { consoleError } from "../misc/misc.js";

/*
const ERR = {
    FATAL: 0,
    ERROR: 1,
    WARN : 2,
    INFO : 3
};
*/

/**
 *
 * @param fallbackProps
 * @returns {JSX.Element}
 */
const ErrorFallback = (fallbackProps) => {
    const { error, resetErrorBoundary } = fallbackProps;

    useEffect(() => {
        const sendError = async (error) => {
            const body = {
                error: error.message
            };
            try {
                const response = await UserErrors.createUserError(body);
                if (response.status !== HTTP.CREATED_201) {
                    throw Error("Can't create contact!");
                }
            } catch (error) {
                consoleError(error.message);
            }
        };
        sendError().then();
    }, []);

    return (
        <div className="error-page min-h-screen flex items-center justify-center">
            <div className="text-center">
                <h1 className="text-5xl font-semibold text-danger md-4">Error!!</h1>
                <p className="text2xl text-gray-300 font-semibold">{error.message}</p>
                <button className="mt-4" onClick={resetErrorBoundary}>
                    Continue
                </button>
            </div>
        </div>
    );
};

/**
 *
 */
/*
class ApiError extends Error {
    constructor(level, message) {
        super(message);
        Error.captureStackTrace(this, this.constructor);

        this.name = this.constructor.name;
        this.level = level;
    }

    level() {
        return this.level;
    }
    stack() {
        return this.stack;
    }

    message() {
        return this.message;
    }
}
*/

export {
    ErrorFallback,
    // ApiError,
    // ERR
} ;