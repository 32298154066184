/******************************************************************************
 * ErrorMsg.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

/**
 *
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
function ErrorMsg({ message }) {
    const spanStyle = { color: "red" };
    return (
        <span style={spanStyle}>{message}</span>
    );
}

export default ErrorMsg;
