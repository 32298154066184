/******************************************************************************
 * dfd-output-form.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * https://www.positronx.io/react-multiple-files-upload-with-node-express-tutorial/
 ******************************************************************************/

import React from "react";
import SVG from "react-inlinesvg";

import Loading from "../../components/Loading.js";
import JobHeader from "../../components/JobHeader.js";

export function DfdOutputForm(
    {
        setupData,
        outputData,
        message,
        executing,
        onClickView,
        onClickDownload
    }) {

    if (executing || !outputData) {
        return <Loading/>;
    }

    return (
        <div>
            <h1>Output</h1>
            <JobHeader setupData={setupData} outputData={outputData}/>
            <hr/>

            {outputData.svgImages.map(file => (
                <div key={file.fileName}>
                    <h2>File: {file.fileName}.sas</h2>
                    File Date: {file.fileDate}<br/>
                    <SVG
                        src={file.svgB64}
                        width="100%"
                        height="100%"
                        title={file.fileName}
                    />
                    <button
                        className="btn btn-primary"
                        onClick={() => onClickView(file)}
                    >View
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-primary"
                        onClick={() => onClickDownload(file)}
                    >Download
                    </button>
                </div>
            ))}
            <h2>{message}</h2>
        </div>
    );
}
