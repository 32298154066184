/******************************************************************************
 * dfd-main.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * See https://github.com/KunalN25/multiple-file-uploads/blob/main/src/App.js
 ******************************************************************************/
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Tab, Tabs} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

import * as JobsApi from "../../api/jobs.api.js";
//import * as JobApi from "../../api/jobs.api.js";
import * as JobTypesApi from "../../api/jobTypes.api.js";
import * as JobLogsApi from "../../api/jobLogs.api.js";
import * as JobXApi from "../../api/jobx.api.js";
import * as JobXFilesApi from "../../api/jobx.x.files.api.js";

import {consoleError, dateToHuman} from "../../misc/misc.js";
import Loading from "../../components/Loading.js";
import {HTTP} from "../../constants/http.js";
import {BLANK_DISPLAY_AFTER_MS} from "../../constants/misc.js";

import {SVG_B64_PREFIX, TAB_OUTPUT, TAB_SETUP} from "./dfd-constants.js";
import {DfdSetupForm} from "./dfd-setup-form.js";
import {DfdOutputForm} from "./dfd-output-form.js";

const thisStub = "dfd";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DfdMain = () => {
    const {user} = useAuth0();
    let thisUserId = undefined;
    if (user) {
        thisUserId = user.sub;
    }
    const navigate = useNavigate();
    const location = useLocation();

    const thisJobId = location.state.jobId;
    const thisTab = location.state.tab || TAB_SETUP;

    const [setupData, setSetupData] = useState(null);
    const [tab, setTab] = useState(thisTab);
    const [message, setMessage] = useState("");
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState([]);
    const [outputData, setOutputData] = useState(null);
    const [executeJobButton, setExecuteJobButton] = useState(false); // Disabled?
    const [executing, setExecuting] = useState(false);


    useEffect(() => {
        setInterval(() => setMessage(""), BLANK_DISPLAY_AFTER_MS);
    });

    /**
     *
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                const jobData = await JobsApi.getJobs(`jobId=${thisJobId}`);
                const jobTypeData = await JobTypesApi.getJobTypes(`jobTypeId=${jobData.data[0].jobTypeId}`);
                const jobXData = await JobXApi.getJobX(`jobId=${thisJobId}`);

                const files = jobXData.data[0].files;
                let svgImages = [];
                for (let i = 0; i < files.length; i++) {
                    const fileName = files[i].fileName;
                    const assetData = await JobXFilesApi.getFile(thisJobId, thisStub, fileName);
                    if (HTTP.OK_200 === assetData.status) {
                        const fileDate = files[i].fileDate;
                        const svgB64 = SVG_B64_PREFIX + btoa(assetData.data);
                        svgImages.push({
                            fileName: fileName,
                            fileDate: dateToHuman(fileDate),
                            svgB64  : svgB64,           // we need B64 to display
                            svgRaw  : assetData.data,    // we need Raw to download
                        });
                    } else {
                        consoleError(assetData.message);
                    }
                }

                const setupInfo = {
                    ...jobData.data[0],
                    jobTypeName: jobTypeData.data[0].name
                };
                setSetupData(setupInfo);

                const outputInfo = {
                    svgImages: svgImages,
                    data     : jobData.data[0],
                };
                setOutputData(outputInfo);

                const filesUploaded = await JobXFilesApi.getFileList(thisJobId);
                setFilesUploaded(filesUploaded.data);

                setExecuteJobButton(filesUploaded.data.length > 0);

            } catch (error) {
                consoleError(error);
                setMessage(`Error creating job: ${error}`);
            }
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    const onSubmitUpdateJobdata = async (data) => {

        let result = await JobsApi.updateJob(thisJobId, data);
        switch (result.status) {
            case HTTP.OK_200:
                //case HTTP.NO_CONTENT_204: // Brand new job may not have been saved yet
                setMessage("Criteria updated.");
                break;
            default:
                setMessage("ERROR: " + result.message);
        }
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickSelectForUpload = async (e) => {
        e.preventDefault();
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        const toBeUploaded = [...filesToBeUploaded];
        // let limitExceeded = false;
        chosenFiles.forEach((file) => {
            if (toBeUploaded.findIndex((f) => f.name === file.name) === -1) {
                toBeUploaded.push(file);
                // if (uploaded.length === MAX_COUNT) setFileLimit(true);
                // if (uploaded.length > MAX_COUNT) {
                //     alert(`You can only add a maximum of ${MAX_COUNT} files`);
                //     setFileLimit(false);
                //     limitExceeded = true;
                //     return true;
                // }
            }
        });
        // if (!limitExceeded)
        setFilesToBeUploaded(toBeUploaded);
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickDeleteJob = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you wish to delete this job?")) {
            return;
        }
        try {
            await JobXApi.deleteJobX(thisJobId);
            await JobsApi.deleteJob(thisJobId);
            navigate("/jobsList");
        } catch (e) {
            setMessage("ERROR: Unable to delete job!");
        }
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickExecute = async (e) => {
        e.preventDefault();
        if (0 < filesToBeUploaded.length) {
            const result = await JobXFilesApi.putFiles(filesToBeUploaded, thisJobId);
            if (HTTP.OK_200 === result.status) {
                setFilesToBeUploaded([]);
            } else {
                const msg = "ERROR: Failed to upload files: " + result.message;
                consoleError(msg);
                setMessage(msg);
                return;
            }
        }

        setTab(TAB_OUTPUT);    // switch to 'output' tab
        setExecuting(true);
        const execResults = await JobsApi.executeJob(thisJobId, setupData);
        setExecuting(false);

        if (HTTP.OK_200 !== execResults.status) {
            setMessage(execResults.message);
            execResults.svgImages = [];
            execResults.data = {
                execStatus: HTTP.NOT_FOUND_404,
                execDate  : "",
                execStdout: "",
                execStderr: "",
                execData  : []
            };
            setOutputData(execResults);
            return;
        }

        const output1 = JSON.parse(execResults.data.execStdout);
        let svgBackend = [];
        let svgFrontend = [];
        for (let f of output1.Files) {
            const fileName = f[2];
            const fileDate = dateToHuman(f[1]);
            svgBackend.push({
                fileName: fileName,
                fileDate: fileDate,
            });
            const svgRaw = await JobXFilesApi.getFile(thisJobId, thisStub, fileName);
            const svgB64 = SVG_B64_PREFIX + btoa(svgRaw.data);
            svgFrontend.push({
                fileName: fileName,
                fileDate: fileDate,
                svgB64  : svgB64,       // we need B64 to display
                svgRaw  : svgRaw.data   // we need Raw to download
            });
        }

        // Save job results

/* Taken care of on the back end
        const updateJobResponse = await JobApi.updateJob(thisJobId,
            {
                lastExecDate  : execResults.data.execDate,
                lastExecStatus: execResults.status,
                lastStderr    : execResults.data.execStderr
            });
        switch (updateJobResponse.status) {
            case HTTP.OK_200:
            case HTTP.NO_CONTENT_204:  // No update performed (data hasn't changed)
                break;
            default:
                const msg = `ERROR: ${updateJobResponse.message}`;
                consoleError(msg);
                setMessage(msg);
        }
*/

        const updateJobXResponse = await JobXApi.updateJobX(thisJobId,
            {
                files: svgBackend
            });
        switch (updateJobXResponse.status) {
            case HTTP.OK_200:
            case HTTP.NO_CONTENT_204:  // No update performed (data hasn't changed)
                break;
            default:
                const msg = `ERROR: ${updateJobXResponse.message}`;
                consoleError(msg);
                setMessage(msg);
        }

        // Save in jobLog
        const jobLogResults = await JobLogsApi.createJobLog(
            {
                userId    : thisUserId,
                jobId     : thisJobId,
                execDate  : execResults.data.execDate,
                execStatus: execResults.status,
            }
        );
        if (HTTP.CREATED_201 !== jobLogResults.status) {
            const msg = `ERROR: ${jobLogResults.message}`;
            consoleError(msg);
            setMessage(msg);
        }

        const outputResults = {
            data     : {
                lastExecStatus: execResults.status,
                lastExecDate  : execResults.data.execDate,
                lastExecStderr: execResults.execStderr
            },
            svgImages: svgFrontend,
        };
        setOutputData(outputResults);
    };

    /**
     *
     * @param file
     */
    const onClickView = (file) => {
        //console.log("onCLickView");
        navigate("/jobx-dfd-view", {
            state: {
                jobId: thisJobId,
                file : file
            }
        });
    };

    /**
     *
     * @param file
     */
    const onClickDownload = (file) => {
        console.log("onCLickDownload()");

        const downloadFile = new Blob([file.svgRaw], {type: "application/svg"});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(downloadFile);
        element.download = file.fileName + ".svg";
        document.body.appendChild(element);
        element.click();
    };

    return setupData ? (
        <div className="col-md-12 container">
            <h1>{setupData.jobTypeName}</h1>
            <Tabs
                id="controlled-tab-example"
                activeKey={tab}
                onSelect={(k) => setTab(k)}
                className="mb-3"
            >
                {/*- Setup Tab -----------------------------*/}
                <Tab eventKey="setup" title="Setup">
                    <DfdSetupForm
                        setupData={setupData}
                        outputData={outputData}
                        message={message}

                        onSubmitUpdateJobdata={onSubmitUpdateJobdata}
                        onClickSelectForUpload={onClickSelectForUpload}
                        onClickDeleteJob={onClickDeleteJob}
                        onClickExecute={onClickExecute}

                        filesToBeUploaded={filesToBeUploaded}
                        filesUploaded={filesUploaded}
                        executeJobButton={executeJobButton}
                        setExecuteJobButton={setExecuteJobButton}
                    />
                </Tab>

                {/*- Output Tab ----------------------------*/}
                <Tab eventKey="output" title="Output">
                    <DfdOutputForm
                        setupData={setupData}
                        outputData={outputData}
                        message={message}

                        executing={executing}
                        onClickView={onClickView}
                        onClickDownload={onClickDownload}
                    />
                </Tab>
            </Tabs>
        </div>
    ) : <Loading/>;
};

export default DfdMain;
