/******************************************************************************
 * api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { consoleError } from "../misc/misc.js";
import { HTTP } from "../constants/http.js";

import { security } from "../auth0/security.js";

const API =
    {
        DELETE: "DELETE",
        GET   : "GET",
        PATCH : "PATCH",
        POST  : "POST"
    };

const api = async (method, url, bodyQuery = null) => {
    const token = await security.getAccessTokenFunc()();
    let serverUrl = process.env.REACT_APP_SERVER_URL + url;

    let options = {
        method : `${method}`,
        headers: {
            Authorization : `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };

    switch (method) {
        case API.GET:
            if (bodyQuery) {
                serverUrl += `?${bodyQuery}`;
            }
            break;
        case API.POST:
        case API.PATCH:
        case API.DELETE:
            if (bodyQuery) {
                options.body = JSON.stringify(bodyQuery);
            }
            break;
        default:
            throw new Error(`${method} is not a valid HTTP method.`);
    }

    let responseData;
    try {
        const response = await fetch(serverUrl, options);

        switch (method) {
            case API.GET:
            case API.POST:
                responseData = await response.json();
                return {
                    status: responseData.status,
                    data  : responseData.data
                };
            case API.PATCH:
            case API.DELETE:
                responseData = await response;
                return {
                    status: responseData.status,
                };
            default:
                throw new Error(`Undefined method: ${method}`)
        }
    } catch (e) {
        consoleError(`API fail: ${method}: ${serverUrl} = ${e.message}`);
        return { status: HTTP.INTERNAL_ERROR_500, message: e.message };
    }
};

export {
    api,
    API
};