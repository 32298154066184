/******************************************************************************
 * wsa-main.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * See https://github.com/KunalN25/multiple-file-uploads/blob/main/src/App.js
 ******************************************************************************/
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Tab, Tabs} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

import * as JobsApi from "../../api/jobs.api.js";
import * as JobTypesApi from "../../api/jobTypes.api.js";
import * as JobLogsApi from "../../api/jobLogs.api.js";
import * as JobXApi from "../../api/jobx.api.js";
import * as JobXFilesApi from "../../api/jobx.x.files.api.js";

import {consoleError} from "../../misc/misc.js";
import Loading from "../../components/Loading.js";
import {HTTP} from "../../constants/http.js";
import {BLANK_DISPLAY_AFTER_MS} from "../../constants/misc.js";

import {SVG_B64_PREFIX, TAB_OUTPUT, TAB_SETUP} from "./wsa-constants.js";
import {WsaSetupForm} from "./wsa-setup-form.js";
import {WsaOutputForm} from "./wsa-output-form.js";

const thisStub = "wsa";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WsaMain = () => {
    const {user} = useAuth0();
    let thisUserId = undefined;
    if (user) {
        thisUserId = user.sub;
    }
    const navigate = useNavigate();
    const location = useLocation();

    const thisJobId = location.state.jobId;
    const thisTab = location.state.tab || TAB_SETUP;

    const [setupData, setSetupData] = useState(null);
    const [tab, setTab] = useState(thisTab);
    const [message, setMessage] = useState("");
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState([]);
    const [outputData, setOutputData] = useState([]);
    const [executeJobButton, setExecuteJobButton] = useState(false); // Disabled?
    const [executing, setExecuting] = useState(false);

    const insertSvg = async files => {
        for (let i = 0; i < files.length; i++) {
            const fileSas = files[i].fileSas.split('.')[0];
            for (let f = 0; f < files[i].outputFiles.length; f++) {
                const fileSvg = files[i].outputFiles[f].fileSvg;
                const svgRaw = await JobXFilesApi.getFile(thisJobId, thisStub, fileSas, fileSvg);
                if (HTTP.OK_200 === svgRaw.status) {
                    const ext = fileSvg.split('.').pop();
                    let svgCooked;
                    switch (ext) {
                        case 'svg':
                            svgCooked = SVG_B64_PREFIX + btoa(svgRaw.data);
                            break;
                        case 'json':
                            svgCooked = JSON.parse(svgRaw.data);
                            break;
                        default:
                            console.error(`${ext} is not a supported image type.`)
                            break;
                    }

                    files[i].outputFiles[f] = {
                        ...files[i].outputFiles[f],
                        svgCooked: svgCooked,       // we need B64 to display
                        svgRaw   : svgRaw.data   // we need Raw to download
                    }
                } else {
                    console.error(`Can't fetch svg '${thisJobId}/${fileSvg}: ${svgRaw.message}`)
                }
            }
        }
        return files;
    }

    useEffect(() => {
        setInterval(() => setMessage(""), BLANK_DISPLAY_AFTER_MS);
    });

    /**
     *
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                const jobData = await JobsApi.getJobs(`jobId=${thisJobId}`);
                const jobTypeData = await JobTypesApi.getJobTypes(`jobTypeId=${jobData.data[0].jobTypeId}`);
                const jobXData = await JobXApi.getJobX(`jobId=${thisJobId}`);

                let svgImages = [];
                if (jobXData.data[0].files) {
                    svgImages = await insertSvg(jobXData.data[0].files);
                }

                const setupInfo = {
                    ...jobData.data[0],
                    jobTypeName: jobTypeData.data[0].name,
                    optionDfd  : jobXData.data[0].optionDfd,
                    optionIpo  : jobXData.data[0].optionIpo,
                    optionPex  : jobXData.data[0].optionPex,
                    optionDex  : jobXData.data[0].optionDex,
                    optionMex  : jobXData.data[0].optionMex,
                    optionLex  : jobXData.data[0].optionLex,
                };
                setSetupData(setupInfo);

                const outputInfo = {
                    svgImages: svgImages,
                    data     : jobData.data[0],
                };
                setOutputData(outputInfo);

                const filesUploaded = await JobXFilesApi.getFileList(thisJobId);
                setFilesUploaded(filesUploaded.data);

                setExecuteJobButton(filesUploaded.data.length > 0);

            } catch (error) {
                consoleError(error);
                setMessage(`Error creating job: ${error}`);
            }
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    const onSubmitUpdateJobdata = async (data) => {

        let jobOk = false;
        const jobData = {
            jobName: data.jobName
        }
        let resultJob = await JobsApi.updateJob(thisJobId, jobData);
        switch (resultJob.status) {
            case HTTP.OK_200:
            case HTTP.NO_CONTENT_204: // Brand-new job may not have been saved yet
                jobOk = true;
                //setMessage("Criteria updated.");
                break;
            default:
                jobOk = false;
            //setMessage("ERROR: " + resultJob.message);
        }

        let jobxOk = false;
        const jobXData = {
            optionDfd: data.optionDfd,
            optionIpo: data.optionIpo,
            optionPex: data.optionPex,
            optionDex: data.optionDex,
            optionLex: data.optionLex,
            optionMex: data.optionMex,
        }

        let resultJobx = await JobXApi.updateJobX(thisJobId, jobXData);
        switch (resultJobx.status) {
            case HTTP.OK_200:
            case HTTP.NO_CONTENT_204: // Brand-new job may not have been saved yet
                //setMessage("Criteria updated.");
                jobxOk = true;
                break;
            default:
                jobxOk = false;
        }

        if (jobOk && jobxOk) {
            setMessage("Criteria updated.");
        } else {
            setMessage("ERROR: " + resultJobx.message);
        }
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickSelectForUpload = async (e) => {
        e.preventDefault();
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        const toBeUploaded = [...filesToBeUploaded];
        // let limitExceeded = false;
        chosenFiles.forEach((file) => {
            if (toBeUploaded.findIndex((f) => f.name === file.name) === -1) {
                toBeUploaded.push(file);
                // if (uploaded.length === MAX_COUNT) setFileLimit(true);
                // if (uploaded.length > MAX_COUNT) {
                //     alert(`You can only add a maximum of ${MAX_COUNT} files`);
                //     setFileLimit(false);
                //     limitExceeded = true;
                //     return true;
                // }
            }
        });
        // if (!limitExceeded)
        setFilesToBeUploaded(toBeUploaded);
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickDeleteJob = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you wish to delete this job?")) {
            return;
        }
        try {
            await JobXApi.deleteJobX(thisJobId);
            await JobsApi.deleteJob(thisJobId);
            navigate("/jobsList");
        } catch (e) {
            setMessage("ERROR: Unable to delete job!");
        }
    };

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onClickExecute = async (e) => {
        e.preventDefault();

        if (!setupData.optionDfd
            || setupData.optionIpo
            || setupData.optionPex
            || setupData.optionDex
            || setupData.optionMex
            || setupData.optionLex
        ) {
            alert("Only DFD works. Select DFD and deselect all others.")
            return;
        }

        if (!setupData.optionDfd
            && !setupData.optionIpo
            && !setupData.optionPex
            && !setupData.optionDex
            && !setupData.optionMex
            && !setupData.optionLex
        ) {
            alert("You must select at least one report")
            return;
        }

        onSubmitUpdateJobdata(setupData).then();

        if (0 < filesToBeUploaded.length) {
            const result = await JobXFilesApi.putFiles(filesToBeUploaded, thisJobId);
            if (HTTP.OK_200 === result.status) {
                setFilesToBeUploaded([]);
            } else {
                const msg = "ERROR: Failed to upload files: " + result.message;
                consoleError(msg);
                setMessage(msg);
                return;
            }
        }

        setTab(TAB_OUTPUT);    // switch to 'output' tab
        setExecuting(true);
        const execResults = await JobsApi.executeJob(thisJobId, setupData);
        setExecuting(false);

        if (HTTP.OK_200 !== execResults.status) {
            setMessage(execResults.message);
            execResults.svgImages = [];
            execResults.data = {
                execStatus: HTTP.NOT_FOUND_404,
                execDate  : "",
                execStdout: "",
                execStderr: "",
                execData  : []
            };
            setOutputData(execResults);
            return;
        }

//        let svgBackend = []; // what we'll save to the backend

        // Save job results
        /* Taken care of on the backend
                const updateJobResponse = await JobApi.updateJob(thisJobId,
                    {
                        lastExecDate  : execResults.data.execDate,
                        lastExecStatus: execResults.status,
                        lastStderr    : execResults.data.execStderr
                    });
                switch (updateJobResponse.status) {
                    case HTTP.OK_200:
                    case HTTP.NO_CONTENT_204:  // No update performed (data hasn't changed)
                        break;
                    default:
                        const msg = `ERROR: ${updateJobResponse.message}`;
                        consoleError(msg);
                        setMessage(msg);
                }
        */

        const updateJobXResponse = await JobXApi.updateJobX(thisJobId,
            {
                files: execResults.data.execData.files
            });
        switch (updateJobXResponse.status) {
            case HTTP.OK_200:
            case HTTP.NO_CONTENT_204:  // No update performed (data hasn't changed)
                break;
            default:
                const msg = `ERROR: ${updateJobXResponse.message}`;
                consoleError(msg);
                setMessage(msg);
        }

        // Save in jobLog
        const jobLogResults = await JobLogsApi.createJobLog(
            {
                userId    : thisUserId,
                jobId     : thisJobId,
                execDate  : execResults.data.execDate,
                execStatus: execResults.status,
            }
        );
        if (HTTP.CREATED_201 !== jobLogResults.status) {
            const msg = `ERROR: ${jobLogResults.message}`;
            consoleError(msg);
            setMessage(msg);
        }

        // Insert svg image data into execResults.data.execData.files
        let files = await insertSvg(execResults.data.execData.files);

        const outputResults = {
            data     : {
                lastExecStatus: execResults.status,
                lastExecDate  : execResults.data.execDate,
                lastExecStderr: execResults.execStderr
            },
            svgImages: files,
        };
        setOutputData(outputResults);
        setMessage("Execution successful.")
    };

    /**
     *
     * @param file
     */
    const onClickView = (file) => {
        console.log("onCLickView");
        navigate("/jobx-wsa-view", {
            state: {
                jobId: thisJobId,
                file : file
            }
        });
    };

    /**
     *
     * @param img
     */
    const onClickDownload = (fileName, img) => {
        console.log("onCLickDownload()");

        const downloadFile = new Blob([img.svgRaw], {type: "application/svg"});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(downloadFile);
        element.download = fileName + '-' + img.fileSvg;
        document.body.appendChild(element);
        element.click();
    };

    /**
     *
     * @param e
     */
    const onChangeDfd = (e) => {
        setupData.optionDfd = e.target.checked;
        setSetupData(setupData);
    }

    const onChangeIpo = (e) => {
        if (!setupData.optionIpo) alert("IPO isn't implemented yet");
        setupData.optionIpo = e.target.checked;
        setSetupData(setupData);
    }

    const onChangePex = (e) => {
        if (!setupData.optionPex) alert("PEX isn't implemented yet");
        setupData.optionPex = e.target.checked;
        setSetupData(setupData);
    }

    const onChangeDex = (e) => {
        if (!setupData.optionDex) alert("DEX isn't implemented yet");
        setupData.optionDex = e.target.checked;
        setSetupData(setupData);
    }

    const onChangeMex = (e) => {
        if (!setupData.optionMex) alert("MEX isn't implemented yet");
        setupData.optionMex = e.target.checked;
        setSetupData(setupData);
    }

    const onChangeLex = (e) => {
        if (!setupData.optionLex) alert("LEX isn't implemented yet");
        setupData.optionLex = e.target.checked;
        setSetupData(setupData);
    }

    return setupData ? (
        <div className="col-md-12 container">
            <h1>{setupData.jobTypeName}</h1>
            <Tabs
                id="controlled-tab-example"
                activeKey={tab}
                onSelect={(k) => setTab(k)}
                className="mb-3"
            >
                {/*- Setup Tab -----------------------------*/}
                <Tab eventKey="setup" title="Setup">
                    <WsaSetupForm
                        setupData={setupData}
                        outputData={outputData}
                        message={message}

                        onChangeDfd={onChangeDfd}
                        onChangeIpo={onChangeIpo}
                        onChangePex={onChangePex}
                        onChangeDex={onChangeDex}
                        onChangeMex={onChangeMex}
                        onChangeLex={onChangeLex}

                        onSubmitUpdateJobdata={onSubmitUpdateJobdata}
                        onClickSelectForUpload={onClickSelectForUpload}
                        onClickDeleteJob={onClickDeleteJob}
                        onClickExecute={onClickExecute}

                        filesToBeUploaded={filesToBeUploaded}
                        filesUploaded={filesUploaded}
                        executeJobButton={executeJobButton}
                        setExecuteJobButton={setExecuteJobButton}
                    />
                </Tab>

                {/*- Output Tab ----------------------------*/}
                <Tab eventKey="output" title="Output">
                    <WsaOutputForm
                        setupData={setupData}
                        outputData={outputData}
                        message={message}

                        executing={executing}
                        onClickView={onClickView}
                        onClickDownload={onClickDownload}
                    />
                </Tab>
            </Tabs>
        </div>
    ) : <Loading/>;
};

export default WsaMain;
