/******************************************************************************
 * dfd-constants.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

const TAB_SETUP = "setup"
const TAB_OUTPUT = "output"
const SVG_B64_PREFIX = "data:image/svg+xml;base64,"

export {
    TAB_SETUP,
    TAB_OUTPUT,
    SVG_B64_PREFIX
};

