/******************************************************************************
 * ContactForm.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import { useForm } from "react-hook-form";

import ErrorMsg from "../components/ErrorMsg.js";

/**
 *
 * @param preloadedValues
 * @param sendContact
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function ContactForm({ preloadedValues, sendContact, message }) {
    const {
        register, handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        sendContact(data);
    };
    return (
        <div className="col-md-12 container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>Contact Us</h1>

                <b>First/Last:</b>&nbsp;<ErrorMsg message={errors.firstName?.message}/>
                &nbsp;&nbsp;
                <ErrorMsg message={errors.lastName?.message}/><br/>
                <input {...register("firstName", { required: "Please enter your first name." })} name="firstName"
                       placeholder="First Name"/>
                &nbsp;
                <input {...register("lastName", { required: "Please enter your last name." })} name="lastName"
                       placeholder="Last Name"/>
                <br/>

                <b>Email:</b>
                &nbsp;&nbsp;
                &nbsp;<ErrorMsg message={errors.email?.message}/><br/>
                <input {...register("email", {
                    required: {
                        value  : true,
                        message: "Please enter your email address",
                    },
                    pattern : {
                        value  :
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address.",
                    },
                })} name="email" placeholder="Email"/><br/>

                <b>Phone:</b><br/>
                <input {...register("phone",)} name="phone" placeholder="Phone"/><br/>

                <b>Message:</b><br/>
                <textarea {...register("msg",)} name="msg" style={{ width: "75%", height: "150px" }} placeholder="msg"/><br/>
                <br/>
                <p>
                    <button className="btn btn-primary btn-sm">Send Request</button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}
