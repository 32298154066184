/******************************************************************************
 * index.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App.js';
import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter,} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                redirect_uri: window.location.origin
            }}
        >
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
