/******************************************************************************
 * Home.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

import SignupButton from "../components/SignupButton.js";
import {ping} from "../api/ping.js";

import "../css/index.css";

import ErrorMsg from "../components/ErrorMsg.js";
import {HTTP} from "../constants/http.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Home = () => {
    const {isAuthenticated} = useAuth0();
    const [isServerAlive, setIsServerAlive] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const response = await ping();
            setIsServerAlive(HTTP.OK_200 === response.status);
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="col-md-12">
                {/*<br/><img src='https://localhost:3001/output/76628d9b-f904-4526-8d7f-86de672794d1/wkp/U06A1_BigData2/dfd.svg' alt='blah' />*/}

                {/*<div height='5%' width='5%'>*/}
                {/*<svg width="100%" height="100%" viewBox="0.0 0.0 90.0 90.0">*/}
                {/*  <circle cx="45" cy="45" r="40" stroke="green" strokeWidth="4" fill="yellow" />*/}
                {/*</svg>*/}
                {/*</div>*/}
                <h1 className="mb-3"> Welcome to Cedalion Risk Management!</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est laborum.
                </p>
                <p>
                    Nunc eu ex quis dui varius feugiat at sed metus. Etiam vitae tempor
                    tellus. Phasellus non enim suscipit, vehicula risus scelerisque,
                    tincidunt diam. Phasellus aliquet urna tellus, a varius sapien tempor
                    nec. Nunc dapibus metus ut ante faucibus suscipit. Pellentesque in
                    ligula in nibh pulvinar feugiat at vitae elit. Proin nulla enim, mattis
                    et sem non, egestas egestas libero. Suspendisse viverra massa eget diam
                    consequat auctor. Sed quis sem quis quam lacinia semper lobortis at
                    arcu. Ut maximus auctor felis, sed fermentum elit varius eu. Phasellus
                    ut elit lacus. Ut maximus tempus lorem quis feugiat. Curabitur quam
                    quam, interdum id orci a, sollicitudin molestie dui. Phasellus eros
                    tellus, suscipit vel placerat non, auctor eget sapien. Donec eu leo
                    auctor, mollis elit a, ullamcorper lorem. Vestibulum orci sapien,
                    eleifend in leo at, fermentum cursus augue.
                </p>
                <p>
                    Nam et turpis viverra, aliquam enim eu, porta dui. Cras blandit
                    imperdiet est, non imperdiet enim tristique vitae. Etiam luctus nunc
                    eget bibendum blandit. Nulla pharetra, urna sit amet laoreet porta, erat
                    lectus accumsan nisl, sit amet varius urna nunc ut odio. Vestibulum nisi
                    nisi, commodo quis rutrum quis, posuere non libero. Sed posuere eget
                    justo in sollicitudin. Praesent fringilla lectus in felis pulvinar, ut
                    blandit eros euismod. Quisque eget ultricies magna. Aliquam sit amet
                    iaculis elit. Pellentesque placerat metus sed fermentum porta. Donec
                    libero nunc, rutrum eget feugiat sit amet, venenatis sed elit. Duis ante
                    turpis, fermentum ut nisi at, pulvinar ullamcorper massa. Aliquam
                    sagittis placerat turpis in efficitur. Fusce venenatis metus justo, sit
                    amet vestibulum tellus hendrerit a.
                </p>
            </div>
            {!isAuthenticated && <div className="text-center"><SignupButton/></div>}
            {!isServerAlive &&
                <div className="text-center"><h1><ErrorMsg message="Can't connect to server!"/></h1></div>}
        </div>
    );
};
export default Home;