/******************************************************************************
 * dfd-setup-form.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

// https://www.positronx.io/react-multiple-files-upload-with-node-express-tutorial/

import React from "react";
import { useForm } from "react-hook-form";

import ErrorMsg from "../../components/ErrorMsg.js";
import JobHeader from "../../components/JobHeader.js";

/**
 *
 * @param preloadedValues
 * @param onSubmitUpdateJobdata
 * @param onClickSelectForUpload
 * @param onClickDeleteJob
 * @param onClickExecute
 * @param filesToBeUploaded
 * @param filesUploaded
 * @param executeJobButton
 * @param setExecuteJobButton
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function DfdSetupForm({
                                 setupData,
                                 outputData,
                                 message,

                                 onSubmitUpdateJobdata,
                                 onClickSelectForUpload,
                                 onClickDeleteJob,
                                 onClickExecute,

                                 filesToBeUploaded,
                                 filesUploaded,
                                 executeJobButton,
                                 setExecuteJobButton
                             }) {
    const {
        register, handleSubmit,
        formState: { errors }
    } = useForm(
        {
            defaultValues: { ...setupData, ...outputData }
        });

    const onSubmit = (data) => {
        const jobData = {
            jobName: data.jobName,
        };
        onSubmitUpdateJobdata(jobData);
    };

    const onClickUploadButton = (e) => {
        e.preventDefault();
        setExecuteJobButton(true);
        document.getElementById("fileUpload").click();
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>Setup</h1>
                <JobHeader setupData={setupData} outputData={outputData}/>

                <div><b>Job Name:</b>&nbsp;<ErrorMsg message={errors.nickname?.message}/></div>
                <div><input {...register("jobName", { required: "Job Name is required!" })} name="jobName"
                            placeholder="Job Name" size="38"/></div>
                <br/>
                <p>
                    <button
                        className="btn btn-primary btn-sm"
                    >
                        Update Job Setup
                    </button>
                    <button
                        className="btn btn-danger btn-sm"
                        style={{ float: "right" }}
                        onClick={onClickDeleteJob}
                    >
                        Delete Job
                    </button>
                </p>
                <hr/>

                <input id="fileUpload" type="file" multiple accept=".sas" style={{ display: "none" }}
                       onChange={onClickSelectForUpload}/>
                <button className="btn btn-primary btn-sm" onClick={onClickUploadButton}>Select .sas files
                    for upload
                </button>
                <br/><br/>

                <table border="1" cellPadding="2">
                    <thead>
                    <tr>
                        <th className="cellBorder">Files to be uploaded on 'Execute Job'</th>
                        <th className="cellBorder"><b>Files already uploaded</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="cellBorder" style={{ verticalAlign: "top" }}>
                            {0 === filesToBeUploaded.length && 0 === filesUploaded.length ?
                                "Select at least one .sas file."
                                :
                                filesToBeUploaded.map(file => (
                                    <div key={file.name}>
                                        {file.name} ({(Math.round(file.size / 1000)).toFixed(0)}K)
                                    </div>
                                ))
                            }

                        </td>
                        <td className="cellBorder" style={{ verticalAlign: "top" }}>
                            {filesUploaded && filesUploaded.map(file => (
                                <div key={file.name}>
                                    {file.name} ({(Math.round(file.size / 1000)).toFixed(0)}K)
                                </div>
                            ))}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <p>
                    <button disabled={!executeJobButton}
                            className="btn btn-success btn-sm"
                            onClick={onClickExecute}
                    >
                        Execute Job
                    </button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}
