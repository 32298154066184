/******************************************************************************
 * App.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";

import { AuthenticationGuard } from "./auth0/AuthenticationGuard.js";

import NavBarCrm from "./components/NavBarCrm.js";
import Footer from "./components/Footer.js";
import { ErrorFallback } from "./components/ErrorFallback.js";

import Home from "./pages/Home.js";
import Features from "./pages/Features.js";
import Contact from "./pages/Contact.js";
import Profile from "./pages/Profile.js";
import JobsList from "./pages/JobsList.js";

import DfdMain from "./jobx/dfd/dfd-main.js";
import DfdView from "./jobx/dfd/dfd-view.js";
import SasSearchMain from "./jobx/sassearch/sassearch-main.js";
import WsaMain from "./jobx/wsa/wsa-main.js";
import WsaView from "./jobx/wsa/wsa-view.js";

import { security } from "./auth0/security.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function App() {
    const { getAccessTokenSilently } = useAuth0();
    security.setAccessTokenFunc(getAccessTokenSilently);

    const navigate = useNavigate();

    return (
        <div id="app" className="d-flex flex-column h-100">
            <NavBarCrm/>
            <div className="container flex-grow-1">
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={() => {
                        navigate("/");
                    }}
                >
                    <Routes>
                        <Route index element={<Home/>}/>
                        <Route
                            path="/features"
                            element={<Features/>}
                        />
                        <Route
                            path="/contact"
                            element={<Contact/>}
                        />
                        <Route
                            path="/jobsList"
                            exact
                            element={<AuthenticationGuard component={JobsList}/>}
                        />
                        <Route
                            path="/jobx-dfd"
                            exact
                            element={<AuthenticationGuard component={DfdMain}/>}
                        />
                        <Route
                            path="/jobx-dfd-view"
                            exact
                            element={<AuthenticationGuard component={DfdView}/>}
                        />
                        <Route
                            path="/jobx-sassearch"
                            exact
                            element={<AuthenticationGuard component={SasSearchMain}/>}
                        />
                        <Route
                            path="/jobx-wsa"
                            exact
                            element={<AuthenticationGuard component={WsaMain}/>}
                        />
                        <Route
                            path="/jobx-wsa-view"
                            exact
                            element={<AuthenticationGuard component={WsaView}/>}
                        />
                        <Route
                            path="/profile"
                            element={<AuthenticationGuard component={Profile}/>}
                        />
                    </Routes>
                </ErrorBoundary>
            </div>
            <Footer/>
        </div>
    );
}

