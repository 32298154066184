/******************************************************************************
 * JobsList.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading.js";

import * as JobXApi from "../api/jobx.api.js";
import * as JobTypesApi from "../api/jobTypes.api.js";
import * as JobsApi from "../api/jobs.api.js";

import { dateToHuman } from "../misc/misc.js";
import { HTTP } from "../constants/http.js";

import { JobsListForm } from "./JobsListForm.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const JobsList = () => {
    const [ data, setData ] = useState({ jobType: "", jobs: [] });
    const [ message, setMessage ] = useState("");
    const { user} = useAuth0();
    const userId = user.sub;
    let navigate = useNavigate();

    const getStub = async (jobTypeId) => {
        // figure out stub
        const jobType = await JobTypesApi.getJobTypes(`jobTypeId=${jobTypeId}`);
        if (jobType.status !== HTTP.OK_200) {
            throw new Error(jobType.message);
        }
        if (0 === jobType.data.length) {
            setMessage(`Can't find jobTypeId=${data.jobTypeId}`);
            return "<unknown>";
        }
        return jobType.data[0].stub;
    };

    useEffect(() => {
        const fetchData = async () => {
            const jobs = await JobsApi.getJobs(`userId=${userId}`);
            const results = [];
            for (const job of jobs.data) {
                // TODO associative array lookup, please
                const jobTypeData = await JobTypesApi.getJobTypes(`jobTypeId=${job.jobTypeId}`);
                results.push(
                    {
                        name       : job.jobName,
                        userId     : job.userId,
                        jobTypeId  : job.jobTypeId,
                        jobTypeName: jobTypeData.data[0].name,
                        jobId      : job.jobId,
                        createDate : dateToHuman(job.createDate)
                    }
                );
            }
            setData(oldState => ({ ...oldState, jobs: results }));
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickAdd = async (event) => {
        event.preventDefault();
        const jobTypeId = data.jobTypeId;
        const stub = await getStub(jobTypeId);

        try {
            // NOTE: job record is created with jobTypeId.
            // The jobx only needs to know the stub
            const clientJob = await JobsApi.createJob(userId, jobTypeId);
            if (clientJob.status !== HTTP.CREATED_201) {
                throw Error("Can't create job!");
            }

            // Create the empty jobx record.
            const clientJobX = await JobXApi.createJobX(clientJob.data.jobId, stub);
            if (clientJobX.status !== HTTP.CREATED_201) {
                throw Error("Can't create job!");
            }

            const pushMe = `/jobx-${stub}`;
            navigate(pushMe, {
                state: clientJob.data
            });
        } catch (error) {
            setMessage(error);
        }
    };

    const onClickEdit = async (event) => {
        event.preventDefault();
        const jobId = event.target.name; // Before getAccessTokenSilently() clobbers it

        const jobData = await JobsApi.getJobs(`jobId=${jobId}`);
        const stub = await getStub(jobData.data[0].jobTypeId);
        const pushMe = `/jobx-${stub}`;
        navigate(pushMe, {
            state: {
                jobId     : jobData.data[0].jobId,
                jobTypeId : jobData.data[0].jobTypeId,
                stub      : stub,
                createDate: jobData.data[0].createDate
            }
        });
    };

    return data ?
        <JobsListForm
            onClickAdd={onClickAdd}
            onClickEdit={onClickEdit}
            data={data}
            setData={setData}
            message={message}
        /> :
        <Loading/>;
};
export default JobsList;
