/******************************************************************************
 * DisplayJobId.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

/**
 *
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
export const DisplayJobId = ({ jobId }) => {

    return "development" === process.env.NODE_ENV ? (<div className={"text-danger"}>
        <div><b>JobId:</b></div>
        <div>{jobId}</div>
    </div>) : (<></>);
};
