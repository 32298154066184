/******************************************************************************
 * ProfileForm.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import { useForm } from "react-hook-form";

import ErrorMsg from "../components/ErrorMsg.js";
import StateSelect from "../components/StateSelect.js";

/**
 *
 * @param preloadedValues
 * @param setUserProfile
 * @param changePassword
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function ProfileForm({
                                preloadedValues,
                                setUserProfile,
                                changePassword,
                                message
                            }) {
    const {
        register, handleSubmit,
        formState: { errors }
    } = useForm(
        {
            defaultValues: preloadedValues
        });

    const onSubmit = (data) => {
        setUserProfile(data);
    };
    const onPassword = () => {
        changePassword();
    };
    return (
        <div className="page-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>Profile</h1>

                <b>Email:</b><br/>
                {preloadedValues.email}
                <br/>

                <b>NickName:</b>&nbsp;<ErrorMsg message={errors.nickname?.message}/><br/>
                <input {...register("nickname",
                    { required: "Nickname is required!"
                    })} name="nickname"
                       placeholder="Nickname"/>
                <br/>

                <b>Company:</b><br/>
                <input {...register("company",)} name="company" placeholder="Company"/>
                <br/>

                <b>First/Last:</b>&nbsp;<ErrorMsg message={errors.given_name?.message}/>&nbsp;&nbsp;<ErrorMsg
                message={errors.family_name?.message}/><br/>
                <input {...register("given_name", { required: "First name is required!" })} name="given_name"
                       placeholder="First Name"/>
                &nbsp;
                <input {...register("family_name", { required: "Last name is required!" })} name="family_name"
                       placeholder="Last Name"/>
                <br/>

                <b>Address:</b><br/>
                <input {...register("address1",)} name="address1" placeholder="address1"/><br/>
                <input {...register("address2",)} name="address2" placeholder="address2"/><br/>

                <b>City/State/Zip:</b><br/>
                <input {...register("city")} name="city" placeholder="city"/>&nbsp;
                <StateSelect label="usState" {...register("usState")} />&nbsp;
                <input type="number" {...register("zip")} name="zip" placeholder="zip"/><br/>

                <b>Phone Number:</b>&nbsp;<ErrorMsg message={errors.phone_number?.message}/><br/>
                <input {...register("phone_number", { required: "Phone number is required!" })} name="phone_number"
                       placeholder="Phone Number"/><br/>
                <br/>

                <b>Last IP:</b><br/>
                {preloadedValues.last_ip}<br/>
                <br/>
                <p>
                    <button className="btn btn-primary btn-sm">Update Profile</button>
                </p>
            </form>

            <hr/>
            <b>Change Password:</b><br/>
            Click this button to have a 'change password' email sent to you.
            <form onSubmit={handleSubmit(onPassword)}>
                <p>
                    <button className="btn btn-primary btn-sm">Send 'Change Password' Email</button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}
