/******************************************************************************
 * Features.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Features = () => {
    return (
        <div className="col-md-12 container">
            <h1 className="mb-3">Features</h1>
            <p>
                Praesent tincidunt consequat purus, efficitur cursus justo elementum id.
                Cras auctor eget neque vitae laoreet. Proin euismod pellentesque felis
                sed vestibulum. Fusce tincidunt, mi eu tempus finibus, massa metus
                semper metus, elementum mollis dolor ipsum et nisi. Curabitur vulputate
                sit amet odio id sollicitudin. Aliquam fermentum condimentum libero, sed
                pretium tortor iaculis non. Duis vulputate rhoncus sapien. Fusce viverra
                diam ultricies ligula tincidunt, a laoreet est aliquam. Duis fermentum
                ut diam quis gravida.
            </p><p>
            Ut ipsum mauris, rhoncus quis pellentesque non, gravida sed nisl.
            Pellentesque ut nulla eu neque ultrices cursus. Cras porta sed lectus a
            lacinia. Quisque tortor est, volutpat id ipsum sed, porttitor accumsan
            enim. Pellentesque quis lorem vitae lectus bibendum facilisis. Sed
            imperdiet faucibus eros nec convallis. Class aptent taciti sociosqu ad
            litora torquent per conubia nostra, per inceptos himenaeos. Donec rutrum
            nulla vitae massa facilisis condimentum. Nullam luctus leo tellus. Cras
            ut ullamcorper urna. Pellentesque volutpat mi in volutpat dignissim.
            Fusce cursus egestas massa, vel scelerisque mauris.
        </p><p>
            Sed vitae luctus sem. Pellentesque ornare congue tincidunt. Donec
            consequat auctor tellus, sit amet porttitor leo congue eget. Suspendisse
            viverra nunc varius, viverra risus et, placerat magna. Duis viverra eu
            sem nec finibus. Sed lobortis mi elementum, rhoncus eros id, tincidunt
            quam. Nunc rutrum dictum lacus imperdiet volutpat. Nunc dapibus magna
            massa, eu feugiat tortor eleifend ac. Maecenas vulputate dignissim
            libero malesuada bibendum. Etiam et massa non enim luctus elementum
            hendrerit eu orci. Duis condimentum sodales elit, a luctus lacus pretium
            eu. Fusce vestibulum dolor vitae felis pellentesque, in scelerisque ex
            tristique. Donec ut nisi vitae dui faucibus mattis.
        </p><p>
            Praesent enim ante, pharetra ut varius a, semper ut lectus. Etiam rutrum
            turpis quis sapien bibendum dapibus. Etiam mollis eleifend auctor. Nulla
            nec sapien a nisl mattis hendrerit. Aenean mattis, velit vel
            pellentesque auctor, mauris felis condimentum ligula, eu feugiat ante
            magna iaculis nunc. Curabitur justo elit, vestibulum a ante tincidunt,
            consectetur tempor urna. Cras mollis turpis quis dui convallis
            sollicitudin. Donec quis ornare lorem. Aliquam viverra lacus eu dictum
            dapibus.
        </p>
        </div>
    );
};

export default Features;
