/******************************************************************************
 * SignupButton.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <button
            className="btn btn-primary btn-lg"
            onClick={() =>
                loginWithRedirect({
                    screen_hint: "signup",
                })
            }
        >
            Sign Up!
        </button>
    );
};

export default SignupButton;
