/******************************************************************************
 * contacts.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param sendData
 * @returns {Promise<{data, status}>}
 */
const createContact = async (sendData) => {
    return api(API.POST, "/api/v1/contacts", sendData);
};

export {
    createContact,
};
