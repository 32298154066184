/******************************************************************************
 * JobsListForm.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, { useState } from "react";

import { JobTypesSelect } from "../components/JobTypesSelect.js";
import SuperTable from "../components/SuperTable.js";

/**
 *
 * @param onClickAdd
 * @param onClickEdit
 * @param data
 * @param setData
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function JobsListForm({
                                 onClickAdd,
                                 onClickEdit,
                                 data,
                                 setData,
                                 message
                             }) {
    const [ addJob, setAddJob ] = useState(true);

    const superColumns = [
        {
            Header  : "EDIT",
            accessor: "edit",
            Cell    :
            /*
                        ({cell: {value}, row: {original}}) => <a href={original.url} target='_blank'
                                                                   rel="noreferrer"><ArrowRightSquare/></a>,
            ---------------------
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    name={option.jobId}
                                                    onClick={onClickEdit}
                                                >
                                                    Edit
                                                </button>
            */
                ({ cell: { value }, row: { original } }) => <button
                    className="btn btn-primary btn-sm"
                    name={original.jobId}
                    key={original.jobId}
                    onClick={onClickEdit}>Edit</button>,

            disableSortBy: true
        },
        {
            Header  : "Job Name",
            accessor: "jobName",
        },
        {
            Header  : "Job Type",
            accessor: "jobTypeName",
        },
        {
            Header  : "Date Created",
            accessor: "createDate",
        },
    ];

    if (0 === data.jobs.length) {
        message = "No jobs present in the database.";
    }
    const onSetJobType = (e) => {
        data.jobTypeId = e.target.value;
        setData(data);
        const disabled = (undefined === e.target.value || "" === e.target.value);
        setAddJob(disabled);
    };

    let superData = [];
    if (data && data.jobs) {
        data.jobs.forEach(row => {
            superData.push({
                jobId      : row.jobId,
                jobName    : row.name,
                jobTypeName: row.jobTypeName,
                createDate : row.createDate,
            });
        });
    }

    return (
        <div className="col-md-12 container">
            <form>
                <h2>Job List</h2>
                <SuperTable data={superData} columns={superColumns}/>
                <p>&nbsp;</p>
                <p>
                    <JobTypesSelect
                        label="JobType"
                        name="Job Type"
                        onChange={onSetJobType}
                    />
                    &nbsp;&nbsp;
                    <button
                        disabled={addJob}
                        className="btn btn-primary btn-sm"
                        name="AddJob"
                        id="AddJob"
                        onClick={onClickAdd}
                    >
                        Add New Job
                    </button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}
