/******************************************************************************
 * PageLoader.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const PageLoader = () => {
    const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

    return (
        <div className="loader">
            <img src={loadingImg} alt="Loading..."/>
        </div>
    );
};
