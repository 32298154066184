/******************************************************************************
 * dfd-setup-form.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

// https://www.positronx.io/react-multiple-files-upload-with-node-express-tutorial/

import React from "react";
import {useForm} from "react-hook-form";

import ErrorMsg from "../../components/ErrorMsg.js";
import JobHeader from "../../components/JobHeader.js";

/**
 *
 * @param preloadedValues
 * @param onSubmitUpdateJobdata
 * @param onClickSelectForUpload
 * @param onClickDeleteJob
 * @param onClickExecute
 * @param filesToBeUploaded
 * @param filesUploaded
 * @param executeJobButton
 * @param setExecuteJobButton
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export function WsaSetupForm({
                                 setupData,
                                 outputData,
                                 message,

                                 onChangeDfd,
                                 onChangeIpo,
                                 onChangePex,
                                 onChangeDex,
                                 onChangeMex,
                                 onChangeLex,

                                 onSubmitUpdateJobdata,
                                 onClickSelectForUpload,
                                 onClickDeleteJob,
                                 onClickExecute,

                                 filesToBeUploaded,
                                 filesUploaded,
                                 executeJobButton,
                                 setExecuteJobButton
                             }) {
    const {
        register, handleSubmit,
        formState: {errors}
    } = useForm(
        {
            defaultValues: {...setupData, ...outputData}
        });

    const onSubmit = (data) => {
        const jobData = {
            jobName: data.jobName,
            optionDfd: setupData.optionDfd,
            optionIpo: setupData.optionIpo,
            optionPex: setupData.optionPex,
            optionDex: setupData.optionDex,
            optionLex: setupData.optionLex,
            optionMex: setupData.optionMex,
        };
        onSubmitUpdateJobdata(jobData);
    };

    const onClickUploadButton = (e) => {
        e.preventDefault();
        setExecuteJobButton(true);
        document.getElementById("fileUpload").click();
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>Setup</h1>
                <JobHeader setupData={setupData} outputData={outputData}/>

                <div><b>Job Name:</b>&nbsp;<ErrorMsg message={errors.nickname?.message}/></div>
                <div><input {...register("jobName", {required: "Job Name is required!"})}
                            name="jobName"
                            placeholder="Job Name" size="38"
                />
                </div>
                <br/>
                <div>
                    <h2>Reports (Select at least one)</h2>
                    <table>
                        <tbody>
                        <tr>
                            <td>Data Flow Diagram (DFD):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionDfd", {})}
                                       name="jobName"
                                       value={setupData.optionDfd}
                                       onChange={onChangeDfd}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Input Processing Output (IPO):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionIpo", {})}
                                       name="optionIpo"
                                       value={setupData.optionIpo}
                                       onChange={onChangeIpo}
                                       disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Program Explorer (PEX):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionPex", {})}
                                       name="optionPex"
                                       value={setupData.optionPex}
                                       onChange={onChangePex}
                                       disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Data Explorer (DEX):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionDex", {})}
                                       name="optionDex"
                                       value={setupData.optionDex}
                                       onChange={onChangeDex}
                                       disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Macro Explorer (MEX):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionMex", {})}
                                       name="optionMex"
                                       value={setupData.optionMex}
                                       onChange={onChangeMex}
                                       disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Linkage Explorer (LEX):</td>
                            <td>
                                <input type='checkbox'
                                       {...register("optionLex", {})}
                                       name="optionLex" value={setupData.optionLex}
                                       onChange={onChangeLex}
                                       disabled
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <p>
                    <button
                        className="btn btn-primary btn-sm"
                    >
                        Update Job Setup
                    </button>
                    <button
                        className="btn btn-danger btn-sm"
                        style={{float: "right"}}
                        onClick={onClickDeleteJob}
                    >
                        Delete Job
                    </button>
                </p>
                <hr/>

                <input id="fileUpload" type="file" multiple accept=".sas" style={{display: "none"}}
                       onChange={onClickSelectForUpload}/>
                <button className="btn btn-primary btn-sm" onClick={onClickUploadButton}>Select .sas files
                    for upload
                </button>
                <br/><br/>

                <table border="1" cellPadding="2">
                    <thead>
                    <tr>
                        <th className="cellBorder">Files to be uploaded on 'Execute Job'</th>
                        <th className="cellBorder"><b>Files already uploaded</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="cellBorder" style={{verticalAlign: "top"}}>
                            {0 === filesToBeUploaded.length && 0 === filesUploaded.length ?
                                "Select at least one .sas file."
                                :
                                filesToBeUploaded.map(file => (
                                    <div key={file.name}>
                                        {file.name} ({(Math.round(file.size / 1000)).toFixed(0)}K)
                                    </div>
                                ))
                            }

                        </td>
                        <td className="cellBorder" style={{verticalAlign: "top"}}>
                            {filesUploaded && filesUploaded.map(file => (
                                <div key={file.name}>
                                    {file.name} ({(Math.round(file.size / 1000)).toFixed(0)}K)
                                </div>
                            ))}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <p>
                    <button disabled={!executeJobButton}
                            className="btn btn-success btn-sm"
                            onClick={onClickExecute}
                    >
                        Execute Job
                    </button>
                </p>
            </form>
            <h2>{message}</h2>
        </div>
    );
}
