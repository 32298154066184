/******************************************************************************
 * StateSelect.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

const usStates = [ "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY" ];
let statesList = usStates.map(v => (
    <option value={v} key={v}>{v}</option>
));

const StateSelect = React.forwardRef(({ onChange, onBlur, name, label }, ref) => {
    return (
        <select name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
            {statesList}
        </select>
    );
});

export default StateSelect;