/******************************************************************************
 * Loading.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

const loadingImg =
    "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = () => (
    <div className="spinner">
        <img src={loadingImg} alt="Loading..." height="300"/>
    </div>
);

export default Loading;
