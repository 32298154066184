/******************************************************************************
 * jobLog.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param body
 * @returns {Promise<{data, status}>}
 */
const createJobLog = async (body) => {
    return api( API.POST, `/api/v1/joblogs`, body);
};
const getJobLogs = async (query) => {
    return api( API.GET, "/api/v1/joblogs", query);
};

export {
    createJobLog,
    getJobLogs,
};