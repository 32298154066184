/******************************************************************************
 * misc.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

const BLANK_DISPLAY_AFTER_MS = 20000;

export {
    BLANK_DISPLAY_AFTER_MS
};