/******************************************************************************
 * JobHeader.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

import { dateToHuman } from "../misc/misc.js";
import { DisplayJobId } from "./DisplayJobId.js";
import { HTTP } from "../constants/http.js";
import Loading from "./Loading.js";

/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const JobHeader = ({ setupData, outputData }) => {

    /*
Expecting:

    setupData: {
        jobId:
        jobTypeName:
        createDate:
    }

    outputData.data {
        lastExecStatus:
        lastExecDate:
        lastStderr:
    }
 */

    if (!setupData || !outputData) {
        return <Loading/>;
    }

    let displayExecutionStatus;
    let stderrMsg = "(none)"
    if (!outputData.data.lastExecStatus) {
        displayExecutionStatus = "Unknown";
    } else if (HTTP.OK_200 === outputData.data.lastExecStatus) {
        displayExecutionStatus = "Success";
    } else {
        displayExecutionStatus = "Failure";
        stderrMsg = outputData.data.lastStderr
    }

    return (
        <div>
            <DisplayJobId jobId={setupData.jobId}></DisplayJobId>
            <div><b>Job Type:</b></div>
            <div>{setupData.jobTypeName}</div>
            <table>
                <thead>
                <tr>
                    <th>Job Created</th>
                    <th>Last Executed</th>
                    <th>Execution Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{dateToHuman(setupData.createDate)}</td>
                    <td>{outputData.data.lastExecDate ? dateToHuman(outputData.data.lastExecDate) : "Not yet"}</td>
                    <td>{displayExecutionStatus}</td>
                </tr>
                </tbody>
            </table>
            <div><b>Error Messages:</b></div>
            <div className="textMonospace"
                 style={{ whiteSpace: "pre-wrap" }}>{stderrMsg}</div>
        </div>
    );
};
export default JobHeader;
