import { api, API } from "./api.js";
import { HTTP } from "../constants/http.js";
import { security } from "../auth0/security.js";

/**
 *
 * @param criteria
 * @returns {Promise<any>}
 */
const getFileList = async (jobId) => {
    const ret = await api( API.GET, `/api/v1/jobx/${jobId}/files`);
    return ret;
};

/**
 *
 * @param jobId
 * @param stub
 * @param fileName
 * @returns {Promise<{message, status: number}|any>}
 */
const getFile = async (jobId, stub, fileId1, fileId2 = null) => {
    const token = await security.getAccessTokenFunc()();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let  url = `/api/v1/jobx/${jobId}/files/${fileId1}`
    if(fileId2) {
        url += `,${fileId2}`
    }
    try {
        const response = await fetch(
            `${serverUrl}${url}`,
            {
                method : API.GET,
                headers: {
                    Authorization : `Bearer ${token}`,
                    "Content-Type": "image/png"
                },
            }
        );
        return await response.json();
    } catch (e) {
        return {
            status : HTTP.INTERNAL_ERROR_500,
            message: e.message
        };
    }
};

/**
 *
 * @param filesToUpload
 * @param jobId
 * @returns {Promise<Response<any, Record<string, any>, number>>}
 */
const putFiles = async (filesToUpload, jobId) => {
    const token = await security.getAccessTokenFunc()();
    const formData = new FormData();
    filesToUpload.forEach(file => {
        formData.append("multipleFiles", file);
    });

    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const response = await fetch(
        `${serverUrl}/api/v1/jobx/${jobId}/files`,
        {
            method : API.POST,
            headers: {
                Authorization: `Bearer ${token}`,
                // NOTE: 'ContentType = X' doesn't work
            },
            body   : formData
        }
    );

    await response;
    return response;
};

export {
    getFileList,
    getFile,
    putFiles,
};