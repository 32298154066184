/******************************************************************************
 * userErrors.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param execData
 * @returns {Promise<{data, status}>}
 */
const createUserError = async (execData) => {
    return api( API.POST, `/api/v1/usererrors`, execData);
};

export {
    createUserError,
};