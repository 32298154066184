/******************************************************************************
 * jobTypes.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api , API } from "./api.js";

/**
 *
 * @param query
 * @returns {Promise<{message, status: number}|{data, status}>}
 */
const getJobTypes = async (query) => {
    return api(API.GET, '/api/v1/jobtypes', query);
};

export { getJobTypes };