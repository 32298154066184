/******************************************************************************
 * jobx.api.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { api, API } from "./api.js";

/**
 *
 * @param jobId
 * @param stub
 * @returns {Promise<{error, status}|{data, status}>}
 */
const createJobX = async (jobId, stub) => {
    const body = {
        jobId: jobId,   // The job with which this jobx record is associated
        stub : stub
    };
    return api( API.POST, `/api/v1/jobx`, body);
};

/**
 *
 * @param filter
 * @returns {Promise<*|{ioSuccess: boolean, message}>}
 */
const getJobX = async (query) => {
    return api( API.GET, '/api/v1/jobx', query);
};

/**
 *
 * @param jobId
 * @param body
 * @returns {Promise<{message, status}|*>}
 */
const updateJobX = async (jobId, body) => {
    return api( API.PATCH, `/api/v1/jobx/${jobId}`, body);
};

/**
 *
 * @param jobId
 * @returns {Promise<{message, status: number}|{status: number}>}
 */
const deleteJobX = async (jobId) => {
    return api( API.DELETE, `/api/v1/jobx/${jobId}`);
};

/**
 *
 * @param query
 * @returns {Promise<{data: *, status: *}|{status: *}|{message: *, status: number}|undefined>}
 */
const getSasData = async (query) => {
    return api( API.GET, '/api/v1/jobx/meta/sassearch/sasconf', query);
};

export {
    createJobX,
    updateJobX,
    getJobX,
    deleteJobX,
    getSasData
};