/******************************************************************************
 * AuthenticationGuard.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "../components/PageLoader.js";

/**
 *
 * @param component
 * @returns {JSX.Element}
 * @constructor
 */
export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <PageLoader/>
            </div>
        ),
    });

    return <Component/>;
};