/******************************************************************************
 * Profile.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import * as UserApi from "../api/user.api.js";
import { ProfileForm } from "./ProfileForm.js";
import Loading from "../components/Loading.js";
import { HTTP } from "../constants/http.js";
import { BLANK_DISPLAY_AFTER_MS } from "../constants/misc.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Profile = () => {
    const { user} = useAuth0();
    const [ data, setData ] = useState(null);
    const [ message, setMessage ] = useState("");

    const userId = user.sub;
    const userEmail = user.email;

    // blank messages after 10 seconds
    useEffect(() => {
        setInterval(() => setMessage(""), BLANK_DISPLAY_AFTER_MS);
    });

    useEffect(() => {
        const fetchData = async () => {
            const profileData = await UserApi.getUserProfile(userId);
            setData(profileData.data);
        };
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setUserProfile = async (newData) => {
        const response = await UserApi.setUserProfile(userId, newData);
        if (HTTP.OK_200 === response.status) {
            setMessage("Profile updated.");
        } else {
            console.error(response);
            setMessage("Error");
        }
    };

    const changePassword = async () => {
        const response = await UserApi.changePassword(userEmail);
        if (HTTP.OK_200 === response.status) {
            setMessage(`'Change Password' email sent to ${userEmail}.`);
        } else {
            console.error(response);
            setMessage("Error");
        }

    };
    return data ?
        <ProfileForm
            preloadedValues={data}
            setUserProfile={setUserProfile}
            changePassword={changePassword}
            message={message}
        /> :
        <Loading/>;
};

export default Profile;
