/******************************************************************************
 * Contact.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React, { useEffect, useState } from "react";
import { ContactForm } from "./ContactForm.js";
import * as ContactsApi from "../api/contacts.api.js";

import { consoleError } from "../misc/misc.js";
import { HTTP } from "../constants/http.js";
import { BLANK_DISPLAY_AFTER_MS } from "../constants/misc.js";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Contact = () => {
    const [ message, setMessage ] = useState("");

    // blank messages after 10 seconds
    useEffect(() => {
        setInterval(() => setMessage(""), BLANK_DISPLAY_AFTER_MS);
    });

    const sendContact = async (myData) => {
        let messageList = [];

        try {
            const sendData = {
                subject: "New Contact",
                textMsg:
                    "First name: " + myData.firstName.trim() + "\n" +
                    "Last name:  " + myData.lastName.trim() + "\n" +
                    "Email:      " + myData.email.trim() + "\n" +
                    "Phone:      " + myData.phone.trim() + "\n" +
                    "Message:    " + myData.msg.trim() + "\n",
                htmlMsg:
                    "<table>" +
                    `<tr><td>First name:</td><td>${myData.firstName.trim()}</td></tr>` +
                    `<tr><td>Last name:</td><td>${myData.lastName.trim()}</td></tr>` +
                    `<tr><td>Email:</td><td>${myData.email.trim()}</td></tr>` +
                    `<tr><td>Phone:</td><td>${myData.phone.trim()}</td></tr>` +
                    `<tr><td>Message:</td><td>${myData.msg.trim()}</td></tr>` +
                    "</table>"
            };

            const response = await ContactsApi.createContact(sendData);
            if (response.status !== HTTP.CREATED_201) {
                throw Error("Can't create contact!");
            }
            messageList.push("Contact request sent.");
        } catch (error) {
            consoleError(error.message);
            messageList.push(error.message);
        }
        setMessage(messageList.join(" "));
    };
    return <ContactForm sendContact={sendContact} message={message}/>;
};

export default Contact;
