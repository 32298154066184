/******************************************************************************
 * wsa-output-form.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * https://www.positronx.io/react-multiple-files-upload-with-node-express-tutorial/
 ******************************************************************************/

import React from "react";

import Loading from "../../components/Loading.js";
import JobHeader from "../../components/JobHeader.js";
import {dateToHuman} from "../../misc/misc.js";
import SVG from "react-inlinesvg";

const SvgLine = ({img, file, onClickView, onClickDownload}) => {
    return (<div>
            <SVG
                src={img.svgCooked}
                width="100%"
                height="100%"
                title={file.fileSvg}
            />
            <button
                className="btn btn-primary"
                onClick={() => onClickView(img)}
            >View
            </button>
            &nbsp;
            <button
                className="btn btn-primary"
                onClick={() => onClickDownload(file.fileSas, img)}
            >Download
            </button>
            <hr/>
        </div>
    )
}

const JsonLine = ({img, file}) => {
    return (<div>
        <table>
            <thead>
            <tr>
                {img.svgCooked.cols.map(header => (
                    <th key={header.name}>{header.name}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {img.svgCooked.rows.map(row => (
                <tr>
                    {row.map(col => (
                        <td key={col}>{col}</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
        <hr/>
    </div>)
}

export function WsaOutputForm(
    {
        setupData,
        outputData,
        message,
        executing,
        onClickView,
        onClickDownload
    }) {

    if (executing || !outputData) {
        return <Loading/>;
    }

    return outputData && outputData.svgImages ? (
        <div>
            <h1>Output</h1>
            <JobHeader setupData={setupData} outputData={outputData}/>
            <hr/>
            {outputData.svgImages.map(file => (
                <div key={file.fileSas}>
                    <h3>File: {file.fileSas}</h3>
                    {file.outputFiles.map(img => {
                        const ext = img.fileSvg.split('.').pop();
                        switch (ext) {
                            case 'svg':
                                return (<div key={img.fileSvg}>
                                    <h4>{img.fileSvg} </h4>
                                    File Date: {dateToHuman(img.fileDate)}<br/>
                                    <SvgLine
                                        img={img}
                                        file={file}
                                        onClickView={onClickView}
                                        onClickDownload={onClickDownload}
                                    />
                                </div>)
                            case 'json':
                                return (<div key={img.fileSvg}>
                                    <h4>{img.fileSvg}</h4>
                                    File Date: {dateToHuman(img.fileDate)}<br/>
                                    <JsonLine
                                        img={img}
                                        file={file}
                                    />
                                </div>)
                            default:
                                return (<div key={img.fileSvg}>
                                    <h4>{img.fileSvg}</h4>
                                    File Date: {dateToHuman(img.fileDate)}<br/>
                                    Unknown file type: {ext}
                                </div>)
                        }
                    })}
                </div>
            ))}
            <h2>{message}</h2>
        </div>
    ) : <Loading/>
}
