/******************************************************************************
 * dfd-view.js
 *
 * Copyright © 2023 Cedalion Risk Management
 *
 * https://www.positronx.io/react-multiple-files-upload-with-node-express-tutorial/
 ******************************************************************************/

import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { TAB_OUTPUT } from "./dfd-constants.js";

/**
 *
 * @param file
 * @param size
 * @param changeSizePercent
 * @param resetSize
 * @param onBack
 * @returns {JSX.Element}
 * @constructor
 */
const MySvg = ({
                   file,
                   size,
                   changeSizePercent,
                   resetSize,
                   onBack
               }) => {
    const sizePercent = `${size}%`;
    return (
        <div className="col-md-12 container">
            <h2>{file.fileName}</h2>
            <div>
                <Button
                    onClick={() => changeSizePercent(-50)}
                >
                    -
                </Button>
                &nbsp;

                <Button
                    onClick={() => changeSizePercent(50)}
                >
                    +
                </Button>
                &nbsp;

                <Button
                    onClick={() => resetSize()}
                >
                    Set to 100%
                </Button>
                &nbsp;

                Size = {size}%
                &nbsp;
                <Button
                    onClick={() => onBack()}
                >
                    Back
                </Button>
            </div>

            <SVG
                src={file.svgB64}
                width={sizePercent}
                height={sizePercent}
                title={file.fileName}
            />

        </div>
    )
        ;
};

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DfdView = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const file = location.state.file;
    const jobId = location.state.jobId;
    const [ size, setSize ] = useState(100);

    const changeSizePercent = (sizeDelta) => {
        const newSize = Math.max(size + parseInt(sizeDelta), 50);
        setSize(newSize);
    };

    const resetSize = () => {
        setSize(100);
    };

    const onBack = () => {
        navigation("/jobx-dfd", {
            state: {
                jobId: jobId,
                tab  : TAB_OUTPUT
            }
        });
    };

    return (
        <div>
            <MySvg
                file={file}
                size={size}
                changeSizePercent={changeSizePercent}
                resetSize={resetSize}
                onBack={onBack}
            />
        </div>
    );
};

export default DfdView;


