/******************************************************************************
 * misc.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import { format } from "date-fns";

/***
 * Mongoose Date to Human
 * @param mgDate
 * @returns {string}
 */
const dateToHuman = (mgDate) => {
    try {
        const thisDate = new Date(mgDate);
        return format(thisDate, "MMM dd yyyy HH:mm:ss");
    } catch (error) {
        return "Invalid date!";
    }
};

/**
 *
 * @param msg
 */
// This is so we can put one breakpoint here and catch all errors in the debugger
const consoleLog = (msg) => {
    console.error(msg);
};

/**
 *
 * @param msg
 */
const consoleError = (msg) => {
    console.error(msg);
};

export { dateToHuman, consoleLog, consoleError };
