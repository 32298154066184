/******************************************************************************
 * Logo.js
 *
 * Copyright © 2023 Cedalion Risk Management
 ******************************************************************************/

import React from "react";

import logo from "../img/Cedalion-logo.svg";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = () => {
    return (
        <img src={logo} alt="Logo" height={50} width={50}/>
    );
};

export default Logo;

